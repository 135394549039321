<template>
  <div class="forget">
    <div class="bar">
      <div @click="onClickLeft" class="box"><van-icon color="#fff" size="20" class="return" name="arrow-left" /></div>
      <div class="user">{{$t('FORGETPASSWORD')}}</div>
    </div>
    <van-form class="formt" @submit="onSubmit">
      <van-field
        v-model="phone"
        name="phone"
        :placeholder="$t('INPUTPHONE')"
      >
        <div slot="label" class="whitecolor">{{$t('CONTACTPHONE')}}</div>
      </van-field>
      <!-- <van-field
        v-model="sms"
        name="message"
        :placeholder="$t('INPUTNOTE')"
      >
        <template #button>
          <van-button v-if="empty" @click="sendAuthCode" native-type="button" class="yanzhengma" size="small" type="warning">{{$t('SEND_SMS')}}</van-button>
          <van-count-down @finish="changeEmpty" ref="countDown" v-else :time="time">
            <template #default="timeData">
              <span class="block">{{timeData.seconds}}</span>
            </template>
          </van-count-down>
        </template>
        <div slot="label" class="whitecolor">{{$t('NOTE')}}</div>
      </van-field> -->
      <van-field
        v-model="password"
        type="password"
        name="password"
        :placeholder="$t('PASSWORD')"
      >
        <div slot="label" class="whitecolor">{{$t('PASSWORD')}}</div>
      </van-field>
      <div class="subbox">
        <van-button class="sub" round block type="default" native-type="submit">{{$t('FORGETPASSWORD')}}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { reset, send } from '@/api/login'
import './css/index.css'
export default {
  name: 'forget',
  data () {
    return {
      phone: '',
      password: '',
      sms: '',
      empty: true,
      time: 6 * 10000
    }
  },
  watch: {
    empty () {
      // console.log('开始倒计时')
    }
  },
  methods: {
    async onSubmit (values) {
      console.log('submit', values)
      const { data } = await reset({
        // captcha: values.message,
        password: values.password,
        phone: values.phone
      })
      console.log(data)
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    async sendAuthCode () {
      if (!this.phone) {
        Toast(this.$t('NOTNULL'))
        return false
      }
      if (this.phone.length < 10) {
        Toast(this.$t('LENGTH10'))
        return false
      }
      // console.log(66 + this.phoneNum)
      const { data } = await send({
        phone: this.phone,
        type: 1
      })
      console.log(data)
      // console.log('验证码')
      this.empty = false
    },
    changeEmpty () {
      this.empty = true
    }
  }
}
</script>

<style scoped>
.block {
  width: 60px !important;
  height: 31.98px;
  display: block;
  width: 22px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background-color: #ff976a;
  line-height: 30px;
}
.forget {
  background: #759FED;
  height: 100%;
  overflow: hidden;
}
.seg {
  margin: 0 30px;
}
.subbox {
  margin: 80px 16px;
}
.sub {
  font-size: 17px;
}
.formt {
  margin-top: 50px;
  padding: 20px;
}
.yanzhengma {
  width: 60px;
  height: 100%;
}
.bar {
  overflow: hidden;
  margin-top: 20px;
}
.box {
  float: left;
  margin-left: 30px;
}
.user {
  float: right;
  font-size: 17px;
  margin-right: 30px;
  color: #fff;
}
.forget >>> .van-field__control {
  color: #fff;
}
</style>
